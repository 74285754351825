@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Barcode+128&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&family=Source+Sans+Pro:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
html {
  scroll-behavior: smooth;
}


.app {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #000000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
}


input {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
}

button {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

::selection {
  background: #fe4040;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:focus {
  outline: none;
}

input {
  caret-color: #fe4040;
}

.custom-swipeable-slide,
div[style*="overflow-x: hidden"]:has(div.spacer) {
  overflow: visible !important;
}

.statusbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: env(safe-area-inset-top);
  background-color: black;
  z-index: 999;
}

.startingscreen {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(black, transparent, rgba(0, 0, 0, 0.9), black),
    url(/static/media/background.4e426555.webp);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.startingscreen .innerbox {
  width: 80%;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: calc(env(safe-area-inset-bottom) + 50px);
}

.innerbox .signinbtn {
  width: 100%;
  height: 56px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(90deg, #fba601, #fd2441);
}

.startingscreen .innerbox img {
  width: 86px;
  object-fit: contain;
  border-radius: 22px;
}

.startingscreen .innerbox h2 {
  font-size: 27px;
  color: white;
  text-align: center;
  margin: 50px 0;
  margin-top: 25px;
  line-height: 25px;
}

.loginoptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #1f1f1f;
  padding: 30px 0;
  position: relative;
}

.singleloginoption {
  height: 45px;
  width: 85%;
  background-color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 8px 0;
}

.fullwrapper {
  background-color: black;
  /* background: linear-gradient(
      rgba(0, 0, 0, 0.801),
      rgba(0, 0, 0, 0.199),
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      rgba(0, 0, 0, 0.199),
      rgba(0, 0, 0, 0.863)
    ),
    url("./images/large.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.navigation .nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  width: 90%;
  height: 60px;
  background: #1f1f1fc7;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  max-width: 500px;
  position: relative;
}

.navigationIndicator {
  position: absolute;
  width: 30%;
  height: calc(100% - 10px);
  background-color: white;
  border-radius: 50px;
  transition: .2s;
}

.navigation a {
  border-radius: 50px;
  width: 30%;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  z-index: 1;
  padding: 0 5px;
}

.navigationIcons {
  transition: .5s;
}

.block1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  margin-bottom: 3rem;
}

.loginlogo {
  width: 86px;
}

.loginlogo img {
  width: 100%;
  height: auto;
}

.block1 form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  position: relative;
}

.block1 form input {
  width: 256px;
  height: 45px;
  margin: 5px 0;
  color: #d6d6d6;
  background: #1f1f1f;
  font-size: 15px;
  border-radius: 10px;
  padding-left: 13px;
  border: none;
  transition: all 0.5s;
}

.username {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0;
}

.username.active {
  width: 256px !important;
  height: 45px !important;
  margin: 5px 0 !important;
  padding-left: 13px !important;
  padding: 2px 3px;
}

.block1 form input::-webkit-input-placeholder {
  color: #d6d6d6;
}

.block1 form input:-ms-input-placeholder {
  color: #d6d6d6;
}

.block1 form input::-ms-input-placeholder {
  color: #d6d6d6;
}

.block1 form input::placeholder {
  color: #d6d6d6;
}

.block1 form input:focus {
  border: 1px solid #fd7f4d;
}

.login-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgotwrapper {
  background-color: black;
  /* background: linear-gradient(
      rgba(0, 0, 0, 0.801),
      rgba(0, 0, 0, 0.199),
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      rgba(0, 0, 0, 0.199),
      rgba(0, 0, 0, 0.863)
    ),
    url("./images/large.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
}

.forgot img {
  width: 86px;
}

.forgot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
}

.forgot form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forgot input {
  width: 256px;
  height: 45px;
  margin: 5px 0;
  color: #d6d6d6;
  background: #1f1f1f;
  font-size: 15px;
  border-radius: 10px;
  padding-left: 13px;
  border: none;
  transition: all 0.5s;
}

.forgot input:focus {
  outline: none;
  border: 1px solid #fd7f4d;
}

.forgot h2 {
  font-size: 21px;
  font-weight: 600;
  color: white;
  margin-top: 39px;
}

.forgot .forgotbutton:hover {
  cursor: pointer;
}

.login-buttons .loginbtn {
  width: 256px;
  padding: 0 13px;
  height: 45px;
  border-radius: 10px;
  border: none;
  margin: 16px 0;
  color: white;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(to right, #fba601 0%, #fd2441 100%);
  box-shadow: 0px 0px 20px 0px #fe4040;
  z-index: 2;
}

.login-buttons .loginbtn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #fba601 0%, #fd2441 100%);
  animation: backgroundanimationbutton 2500ms ease-in-out 0s infinite
    alternate-reverse;
  opacity: 0;
  border-radius: 10px;
  z-index: -1;
}

.forgot .forgotbutton {
  width: 17rem;
  height: 45px;
  border-radius: 10px;
  border: none;
  margin: 20px 0;
  color: white;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(to right, #fba601 0%, #fd2441 100%);
  box-shadow: 0px 0px 20px 0px #fe4040;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.forgot .forgotbutton::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #fba601 0%, #fd2441 100%);
  animation: backgroundanimationbutton 2500ms ease-in-out 0s infinite
    alternate-reverse;
  opacity: 0;
  border-radius: 10px;
  z-index: -1;
}

.backtologin {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  margin-top: 15px;
  margin-bottom: -25px;
}

.forgotlabel {
  transform: scale(1);
  transition: all 0.5s;
  text-align: center;
  text-decoration: none;
  color: #888888;
  height: 12px;
  margin: 16px 0;
}

.forgotlabel.inactive {
  transform: scale(0);
  margin: 0;
  height: 0;
  flex-grow: 0;
}

@-webkit-keyframes backgroundanimationbutton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  /* background: linear-gradient(90deg, #fba601, #fd2441); */
}

@keyframes backgroundanimationbutton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  /* background: linear-gradient(90deg, #fba601, #fd2441); */
}

.login-buttons .loginbtn:hover {
  cursor: pointer;
}

.login-buttons .signinbtn {
  width: 15.5rem;
  height: 56px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  background: transparent;
}

.signinbtn.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signinbtn:hover {
  cursor: pointer;
}

.error-text {
  color: #fd7f4d;
  font-size: 14px;
  margin: 10px 0;
  transition: all 0.5s;
  text-align: center;
}

.error-text.not {
  font-size: 0;
  margin: 0;
}

.icons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  text-decoration: none;
}

.icons svg {
  margin: 0 1.5rem;
  font-size: 25px;
}

.icons svg:hover {
  cursor: pointer;
}

.icons svg:nth-child(1) {
  color: #fe4040;
}

.icons svg:nth-child(2) {
  color: #44d2f4;
}

.icons svg:nth-child(3) {
  color: #4284de;
}

.homehead {
  background: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.72), transparent),
    url(/static/media/large.837fc2a8.png);
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homeheadRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-right: 20px;
  margin-top: -25px;
}

.homehead span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 23px;
  font-weight: 600;
  font-family: "Fredoka", sans-serif;
  margin-left: 20px;
  margin-top: -25px;
}

.homehead img {
  border-radius: 100%;
  margin-right: 5px;
  height: 35px;
  width: 35px;
  object-fit: cover;
}

.homeheadprofileimage {
  border: 2px solid white;
  border-radius: 100%;
  min-width: 35px;
  min-height: 35px;
}

.homehead h1 {
  margin: 0;
  color: white;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-left: 16px;
  margin-top: -25px;
}

.spacer { 
  margin: 0 20px;
  transition: .4s;
}

.homenavpopup {
  width: 100%;
  height: 50px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: env(safe-area-inset-top);
  z-index: 100;
}

.homenavpopup span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 23px;
  font-weight: 600;
  font-family: "Fredoka", sans-serif;
  margin-left: 20px;
}

.homenavpopup img {
  border-radius: 100%;
  margin-right: 5px;
  height: 35px;
  width: 35px;
  object-fit: cover;
}

.homenavpopup .homeheadprofileimage {
  margin-right: 20px;
  margin-top: 0;
  border: 2px solid white;
  border-radius: 100%;
  min-width: 35px;
  min-height: 35px;
}

.homeactionwrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.homeaction {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 16px;
  border-bottom: 1px solid #1e1c1c;
}

.homebutton {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
}

.homebutton p {
  color: #7f7e7e;
}

.homeaction button {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  border: none;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 1;
}

.homeaction button svg {
  font-size: 28px;
}

.homeaction button:hover {
  cursor: pointer;
}

.homeaction .homebutton .driver {
  color: #2fbd43;
  border: 2px solid #113a14;
}

.homeaction .homebutton .friends {
  color: #4284de;
  border: 2px solid #193c54;
}

.homeaction .homebutton .cake {
  color: violet;
  border: 2px solid #571435;
}

.homeaction .homebutton .map {
  color: #fe4040;
  border: 2px solid #591415;
}

.homemain {
  padding-top: 10px;
  padding-bottom: 96px;
  margin-top: -25px;
  overflow: hidden;
  background-color: #040000;
  border-radius: 20px 20px 0 0;
}

.homemain h1 {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.homemain .drivefriends {
  margin: 16px 0;
}

.homemain .drivefriends h2 {
  font-size: 17px;
  font-weight: 600;
  color: #888888;
  margin: 0;
}

.posts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drivepost {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #1f1f1f;
  border-radius: 10px;
  margin: 7px 0;
  padding: 11px 0;
  transition: 1s;
  opacity: 1;
}

.drivepost p {
  max-width: 7rem;
  min-width: 6.3rem;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
}

.drivepost.party img {
  width: 5rem;
  border-radius: 20px 0 0 20px;
}

.drivepost.profile {
  background-color: #1f1f1f;
}

.drivepost.profile p {
  color: white;
  font-weight: 500;
}

.startsat {
  width: auto;
  min-width: auto !important;
}

.drivepost span {
  font-size: 10px;
  word-break: break-word;
  color: #8c8c8c;
}

.drivepost.party p {
  max-width: 13rem;
  min-width: 7rem;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.drivepost.party span {
  max-width: 85%;
}

.drivepost.friend {
  transition: 1s;
  opacity: 0;
}

.mapholder {
  height: 100%;
  width: 100vw;
}

.promotionMap {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  transition: .3s;
}

.promotionMap > div, .promotionMap > div > div, .promotionMap > div > div > div, .promotionMap > div > div > div > .gm-style {
  overflow: hidden;
  border-radius: 20px;
}

.innerMapOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.Friendhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiDrawer-paper {
  border-radius: 50px 50px 0 0;
}

.addfriend {
  min-height: 90vh;
  width: 100%;
  border-radius: 50px 50px 0 0;
  background: linear-gradient(60deg, #fe4040, #f9315a);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.inputfriend {
  position: relative;
  width: 100%;
}

.inputfriendfirst {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.3rem;
}

.inputfriendfirst a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-left: -10px;
}

.inputfriendfirst svg {
  position: absolute;
  right: 0.5rem;
  top: 0;
}

/* .inputfriend svg {
  position: absolute;
  right: 0.5rem;
  top: 3.4rem;
} */

.drivepost.add {
  padding: 11px 0;
}

.drivepost.add p {
  color: white;
}

.addfrienddiv {
  margin: 0 16px;
}

.addfrienddiv label h2 {
  margin: 16px 0;
  font-weight: 600;
  font-size: 20px;
  color: white;
}

.addfrienddiv label input {
  margin-bottom: 16px;
}

.scndlabel h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
}

.addfrienddiv .scndlabel input {
  margin-bottom: 0;
}

.addfrienddiv input {
  width: 100%;
  height: 2rem;
  background: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  font-size: 18px;
}

.addfrienddiv input::-webkit-input-placeholder {
  color: white;
}

.addfrienddiv input:-ms-input-placeholder {
  color: white;
}

.addfrienddiv input::-ms-input-placeholder {
  color: white;
}

.addfrienddiv input::placeholder {
  color: white;
}

.iphonething {
  position: absolute;
  top: 10px;
  width: 10rem;
  height: 10px;
  border-radius: 20px;
  background: white;
}

.drawerbuttons {
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
}

.drawerbuttons button {
  margin: 0.5rem;
  width: 10rem;
  height: 2rem;
  background: transparent;
  border-radius: 20px;
  border: 1px solid #fff;
  font-size: 20px;
  color: white;
}

.leaveAdd {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
}

.profilewrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 0;
  transition: 0.5s ease-in-out;
}

.profilewrapperdarkmode {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 0;
  transition: 0.5s ease-in-out;
  background-color: #262626;
}

.userimage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(90deg, white, rgb(209, 209, 209));
  height: 10rem;
  width: 90%;
  margin-top: 16px;
  padding: 0;
  border-radius: 0 50px 50px 0;
  transition: 0.5s;
}

.userimage.darkmode {
  background: linear-gradient(90deg, #a6a6a6, #737373);
  transition: 0.9s;
}

.profilepoststext.darkmode {
  color: white;
}

.userinfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userinfo h2 {
  font-weight: 400;
  margin: 0px 16px;
}

.userinfo input {
  width: 256px;
  border: none;
  font-size: 18px;
  padding: 0.5rem 0;
  margin: 0 16px;
  border-radius: 20px;
  padding-left: 16px;
}

.userinfo h3 {
  font-weight: 300;
  margin: 0px 16px;
}

.infobox {
  border-bottom: 1px solid grey;
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background: white;
  transition: 0.8s ease-in-out;
}

.infoboxdarkmode {
  border-bottom: 1px solid grey;
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  transition: 0.8s ease-in-out;
}

.infobox p {
  margin: 5px 0;
  color: grey;
  margin-left: 16px;
  transition: 0.9s;
}

.infoboxdarkmode p {
  margin: 5px 0;
  color: #b3b3b3;
  margin-left: 16px;
  transition: 0.9s;
}

.infobox h1 {
  font-weight: 300;
  display: flex;
  align-items: center;
}

.infoboxdarkmode h1 {
  font-weight: 300;
  display: flex;
  align-items: center;
}

.infoboxdarkmode a {
  color: white;
  text-decoration: none;
}

.infoboxdarkmode h3 {
  color: white;
}

.infoboxdarkmode h2 {
  color: white;
}

#fileupload {
  display: none;
  opacity: 0;
}

.profileposts .profilepoststext {
  text-align: start;
  font-size: 16px;
  font-weight: 600;
  color: #7b7f85;
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.fileupdate {
  background: orange;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 5rem;
  left: 5.5rem;
}

.drivepost.add .accept {
  border: none;
  border-radius: 7px;
  font-weight: 600;
  background-color: #4380eb;
  color: white;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.5rem;
  height: 37px;
  margin-right: -19px;
}

.decline {
  border: none;
  width: auto;
  background: transparent;
  margin-left: 8px;
}

.drivebuttondiv {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activedriver {
  border: none;
  background: linear-gradient(90deg, #fe4040, #fe7640);
  border-radius: 8px;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  z-index: 3;
  transition: all 0.3s;
}

.activedriver:active {
  transform: scale(0.95);
}

.activedriver:hover {
  cursor: pointer;
}

.activedriver.createDrawer {
  width: 50%;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  margin: 0;
}

.confirmbutton {
  width: 129px;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 40px;
  border-radius: 10px;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #fe4040, #fe7640);
  font-size: 16px;
  color: white;
  font-weight: 600;
  margin: 0;
}

.activedriver.create {
  margin-top: 100px;
  margin-bottom: -300px;
}
/* Site-Transition */

.slide-enter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: none;
  overflow-y: auto;
}

.slide-enter-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.slide-enter.driver-post,
.slide-enter.DrivePostWrapper,
.slide-enter.clubshome,
.slide-enter.clubshome.promotions,
.slide-enter.searchmodal,
.slide-enter.userprofileouterwrapper,
.slide-enter.partymodalwrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: translateX(100%);
  z-index: 198;
  transition: transform 400ms cubic-bezier(0.3, 0.8, 0.2, 1);
}

.slide-enter-done {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: none;
  overflow-y: auto;
}

.slide-enter-active.driver-post,
.slide-enter-active.DrivePostWrapper,
.slide-enter-active.clubshome,
.slide-enter-active.clubshome.promotions,
.slide-enter-active.searchmodal,
.slide-enter-active.userprofileouterwrapper,
.slide-enter-active.partymodalwrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: none;
  z-index: 198;
  transition: transform 400ms cubic-bezier(0.3, 0.8, 0.2, 1);
}

.slide-exit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: none;
  overflow-y: auto;
}

.slide-exit-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: none;
  overflow-y: auto;
}

.slide-exit-done {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transform: none;
  overflow-y: auto;
}

.slide-exit.driver-post,
.slide-exit.DrivePostWrapper,
.slide-exit.clubshome,
.slide-exit.clubshome.promotions,
.slide-exit.searchmodal,
.slide-exit.userprofileouterwrapper,
.slide-exit.partymodalwrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  transform: none;
  z-index: 199;
  transition: transform 400ms cubic-bezier(0.3, 0.8, 0.2, 1);
}

.slide-exit-active.driver-post,
.slide-exit-active.DrivePostWrapper,
.slide-exit-active.clubshome,
.slide-exit-active.clubshome.promotions,
.slide-exit-active.searchmodal,
.slide-exit-active.partymodalwrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  transform: translateX(100%);
  z-index: 199;
  transition: transform 400ms cubic-bezier(0.3, 0.8, 0.2, 1);
}

.createAdrivePost {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createInner {
  width: 100%;
}

.addwrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.addholder {
  padding: 0px 24px;
  border-radius: 0 140px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.editholder {
  padding: 0;
  margin: 0 25px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.editholder input {
  width: calc(100% - 15px);
  font-size: 16px;
  margin-bottom: 10px;
  color: #d6d6d6;
  background-color: #1f1f1f;
  border-radius: 10px;
  border: none;
  transition: all 0.5s;
  transform: scale(0);
  padding: 15px 0 15px 15px;
}

.editholder input:focus {
  outline: none;
}

.editholder textarea {
  width: calc(100% - 15px);
  font-size: 16px;
  margin-bottom: 10px;
  color: #d6d6d6;
  background-color: #1f1f1f;
  border-radius: 10px;
  border: none;
  transition: all 0.5s;
  transform: scale(0);
  padding: 15px 0 15px 15px;
  resize: none;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
}

.editholder textarea:focus {
  outline: none;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #707070;
}

.divertwo {
  height: 1px;
  width: 100%;
  background-color: #272727;
}

.createDrawer {
  height: 100vh;
  max-width: 100%;
  /* background-color: #1f1f1f; */
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  margin-top: env(safe-area-inset-top);
}

.createDrawer h2 {
  font-size: 24px;
  margin: 0 5px;
  color: #f8f8f8;
}

.createDrawerinputs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 67px;
  width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.createDrawerinputs h2 {
  font-size: 66px;
  font-weight: 600;
  color: #a5a5a5;
}

.createDrawerinputs input {
  border-radius: 20px;
  color: #a5a5a5;
  width: 25%;
  height: 100%;
  font-weight: 600;
  border: 1px solid transparent;
  text-align: center;
  font-size: 66px;
  background-color: #272727;
}

.createDrawerinputs input:focus {
  border: 1px solid #fd7f4d;
}

.activedriver.create.wiggle {
  -webkit-animation: errmessage 0.7s;
          animation: errmessage 0.7s;
}

.createDrawerinputs span {
  width: 30px;
  height: 30px;
  border: 2px solid #fd7f4d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fd7f4d;
  border-radius: 100%;
}

.timezonecreate {
  height: 100%;
  width: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.timezonecreate p {
  width: 100%;
  height: 50%;
  margin: 0;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.timezonecreate p:nth-child(1) {
  border-radius: 20px 20px 0 0;
}

.timezonecreate p:nth-child(2) {
  border-radius: 0 0 20px 20px;
}

.addholder h1 {
  font-size: 19px;
  font-weight: 300;
  text-align: center;
}

.addholder h2 {
  font-size: 23px;
  color: white;
}

.addholder input {
  width: calc(100% - 15px);
  font-size: 16px;
  margin-bottom: 10px;
  color: #d6d6d6;
  background-color: #1f1f1f;
  border-radius: 10px;
  border: none;
  transition: 0.5s all;
  padding: 15px 0 15px 15px;
  transform: scale(0);
}

.addholder::-webkit-input-placeholder {
  color: #888888;
}

.addholder:-ms-input-placeholder {
  color: #888888;
}

.addholder::-ms-input-placeholder {
  color: #888888;
}

.addholder::placeholder {
  color: #888888;
}

.addholder input:focus {
  outline-width: 0px;
}

.addheader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  border-bottom: 1px solid #707070;
  width: 100vw;
  background: #000000;
  z-index: 200;
  margin-top: env(safe-area-inset-top);
}

.addheader > * {
  width: 70px;
}

.addheader h2 {
  font-size: 18px;
  font-weight: 600;
  -webkit-animation: addtitle 0.5s forwards;
          animation: addtitle 0.5s forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@-webkit-keyframes addtitle {
  0% {
    opacity: 0;
    margin-right: -500px;
  }
  100% {
    opacity: 1;
    margin-right: 0;
  }
}

@keyframes addtitle {
  0% {
    opacity: 0;
    margin-right: -500px;
  }
  100% {
    opacity: 1;
    margin-right: 0;
  }
}

.addwrapper a {
  color: #4380eb;
  text-decoration: none;
  font-size: 18px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addwrapper button {
  position: absolute;
  right: 20px;
  color: #4380eb;
  border: none;
  font-size: 18px;
  background: transparent;
}

.send {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: auto;
}

.error-text.adding {
  margin: 0;
}

.borderposts {
  background: linear-gradient(90deg, #fe4040, #fe7640);
  width: 4.4rem;
  height: 4.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 100%;
}

.borderposts.private {
  background: linear-gradient(to right, #85d563, #7db854);
}

.white {
  position: absolute;
  background-color: #040000;
  width: 91%;
  height: 91%;
  border-radius: 100%;
  transition: 1s;
}

.whiterounded {
  height: 34px;
  width: 34px;
  z-index: 4;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: -0.5rem;
}

.whitepost {
  position: absolute;
  background-color: white;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 100%;
}

.newprofileimage label {
  position: absolute;
  top: 5rem;
  left: 5rem;
}

.storytest {
  position: absolute;
}

.inputfriendfirst.friends .searchbar.friend {
  border: 0px;
  background-color: #1f1f1f;
  color: #d6d6d6;
  height: 30px;
  width: 100%;
  border-radius: 8px;
  font-size: 18px;
  padding: 8px 0;
  padding-left: 10px;
  transition: all 0.5s;
}

.inputfriendfirst.friends .searchbar.friend::-webkit-input-placeholder {
  color: #a5a5a5;
}

.inputfriendfirst.friends .searchbar.friend:-ms-input-placeholder {
  color: #a5a5a5;
}

.inputfriendfirst.friends .searchbar.friend::-ms-input-placeholder {
  color: #a5a5a5;
}

.inputfriendfirst.friends .searchbar.friend::placeholder {
  color: #a5a5a5;
}

.searchbar.friend.adding {
  width: 100% !important;
}

.closemodaliconarrow {
  position: static !important;
  position: initial !important;
}

.inputfriendfirst.friends svg {
  position: absolute;
  top: calc(50% - 12px);
  right: 0%;
  color: #9f9fa3;
}

.inputfriendfirst.friends.adding .addingsvg {
  top: calc(50% - 12px);
  padding-right: 17px;
}

.friendspartytitlebox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addfriendbutton {
  border-radius: 7px;
  height: 37px;
  padding: 0 16px;
  width: 113px;
  background-color: #4380eb;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
}

.addfriendbutton.userprofile {
  margin: 0;
  margin-bottom: 30px;
  margin-right: 10%;
  padding: 0 50px;
  border-radius: 30px;
  height: 43px;
  max-height: 52px;
  width: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addfriendbutton:hover {
  cursor: pointer;
}

.addfriendbutton.request {
  background-color: #131213;
  border: 1px solid #4380eb;
  color: #4380eb;
}

.addfriendbutton.group {
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  background-color: transparent;
  border: none;
  color: grey;
}

.collapseinner {
  width: 100%;
  background: #f6f6f6;
  border-radius: 20px;
}

.collapseinner ul {
  margin: 0;
  margin-left: 16px;
  padding: 10px 0;
  list-style: none;
}

.collapseinner ul li {
  font-family: "Titillium Web", sans-serif;
}

.collapseinner ul li p {
  font-weight: 600;
}

.collapseinner ul li p span {
  font-weight: 400;
  word-break: break-word;
}

.collapseinner ul li a {
  color: black;
}

.drivepost.party {
  justify-content: space-between;
  position: relative;
}

.drivepost.party:hover {
  cursor: pointer;
}

.drivepost.party p {
  font-size: 13px !important;
  min-width: auto;
}

.tutorialcard {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tutorialcard img {
  width: 60%;
  height: auto;
}

.tutorialcard h1 {
  margin: 16px 0;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 400;
}

.tutorialcard p {
  text-align: center;
  margin: 1.5rem 16px;
  font-weight: 300;
}

.tutorialbtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testedlabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1f1f1f;
  color: #d6d6d6;
  margin: 5px 0;
  width: 256px;
  height: 30px;
  padding: 10px 0.5rem;
  border-radius: 10px;
  transition: all 0.5s;
  opacity: 1;
}

.testedlabel.deactive {
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 10px;
  opacity: 0;
}

.testedselect {
  background-color: transparent;
  color: #d6d6d6;
  border: none;
  font-size: 16px;
  transition: all 0.5s;
}

.testedselect.deactive {
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.tutorialbtn button {
  margin: 0 16px;
}

.infobox.darkmode {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.infoboxdarkmode.darkmode {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.drivelink {
  text-decoration: none;
  color: black;
  width: 100%;
  position: relative;
}

.added {
  text-align: center;
  min-width: auto !important;
  max-width: auto !important;
  background-color: #4380eb;
  height: 2rem;
  border-radius: 40px;
  color: white !important;
  padding: 0 0.5rem;
}

.added.requested {
  background-color: white;
  color: black !important;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 7px;
}

.remove {
  background-color: #fe4040;
  color: white;
  border: none;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.postwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postwrapper > * {
  flex: 0 0 auto;
}

/* .postwrapper.profile {
  margin-bottom: 7rem;
} */

.postheader {
  background: url(/static/media/postbackground.80be5a0f.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  position: relative;
}

.postheader.party {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.postheader.profile {
  background-size: cover;
  background-repeat: no-repeat;
}

.postheader svg {
  position: absolute;
  top: 16px;
  left: 16px;
  color: white;
  background-color: rgba(99, 99, 99, 0.616);
  border-radius: 100%;
  padding: 0.3rem;
}

.postbody {
  width: 100%;
  background-color: #000000;
  box-shadow: 1px 1px 26px 10px rgba(0, 0, 0, 0.24);
  margin-top: -3rem;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}

.driverprofilepicture {
  border-radius: 100%;
  width: 7rem;
  height: 7rem;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.borderposts.post {
  width: 8rem;
  height: 8rem;
  position: absolute;
  top: -4.2rem;
  left: 50%;
  transform: translateX(-50%);
}

.postbody h1 {
  text-align: center;
  margin: 0;
  margin-top: 75px;
  font-weight: 600;
  word-break: break-word;
  font-size: 19px;
  color: white;
}

.postbody h2 {
  color: #8b8d92;
  margin: 0;
  margin-top: 5px;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}

.postbodyhead {
  padding-bottom: 16px;
}

.postinfo {
  height: 48px;
  background-color: #1f1f1f;
  margin: 8px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.postinfo h2 {
  text-align: start;
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
  margin-top: 0;
  color: #7b7f85;
  display: flex;
  align-items: center;
}

.postinfo h2 span {
  font-weight: 400;
  word-break: break-word;
  margin-left: 8px;
}

.hoponbtns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -4rem;
  margin-top: 16px;
}

.hopon {
  border-radius: 100%;
  background-color: white;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 30px 13px rgba(0, 0, 0, 0.274);
  margin: 0 6px;
}

.usersettingsmodal {
  border-radius: 15px;
  border: none;
  background-color: #262626;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding: 0 20px;
  margin: 0 20px;
}

.usersettingsmodal h1 {
  margin: 0;
  color: white;
  font-weight: 700;
  font-size: 17px;
}

.usersettingsmodal p {
  margin: 0 16px;
  margin-bottom: 16px;
  color: grey;
  font-weight: 500;
  text-align: center;
  font-size: 15px;
}

.alert {
  border-radius: 15px;
  border: none;
  background-color: #262626;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding: 0 20px;
  padding-top: 20px;
  margin: 0 20px;
}

.alert h1 {
  margin: 0;
  color: white;
  font-weight: 700;
  font-size: 17px;
}

.alert p {
  margin: 0 16px;
  margin-bottom: 16px;
  color: grey;
  font-weight: 500;
  text-align: center;
  font-size: 15px;
}

.alert.socials h1 {
  font-size: 22px;
  margin-bottom: 15px;
}

.alert.socials input {
  background-color: transparent;
  font-size: 16px;
  color: grey;
  font-weight: 500;
  border: none;
  text-align: center;
  padding: 15px;
}

.alertbutton {
  height: 3rem;
  border-top: 1px solid #2c2c2c;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  height: 55px;
  padding: 0 20px;
  border-radius: 0 0 15px 15px;
}

.alertbutton:active {
  background-color: #363636;
}

.alertbutton.single:active {
  background-color: #363636;
}

.alertavatar {
  background-color: rgb(230, 230, 230);
  padding: 1px;
  border-radius: 100%;
  margin: 10px 0;
  margin-top: 0;
}

.alertavatar.socials {
  background-color: transparent;
}

.alertbutton.single {
  border: none;
  border-radius: 15px;
  background-color: #262626;
  color: white;
  font-weight: 500;
  margin: 8px 20px;
  width: auto;
  padding: 0;
  margin-bottom: 13px;
}

.notice {
  border-radius: 10px;
  border: none;
  background-color: #262626;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
  margin: 0 35px;
}

.notice h1 {
  margin: 0;
  color: white;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
}

.notice p {
  margin: 0 10px;
  margin-top: 5px;
  margin-bottom: 16px;
  color: white;
  font-weight: 400;
  text-align: center;
  font-size: 15px;
  word-break: break-word;
}

.notice p span {
  color: #fe7640;
}

.notice button {
  margin: 5px 8px;
  background-color: #fe7640;
  border-radius: 8px;
  border: none;
  height: 45px;
  width: 100%;
  color: white;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notice button:last-child {
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: 0;
  color: #fd7f4d;
}

.postinfo.seats {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.drivepost.add.seats {
  margin: 5px 0;
  width: 100%;
  background-color: transparent;
}

.postinfo.collapse {
  position: relative;
}

.postinfo.collapse .collapsedown {
  position: absolute;
  right: 16px;
}

.postinfo.collapse .collapsedown.spin {
  -webkit-animation: spinner 0.5s forwards;
          animation: spinner 0.5s forwards;
}

@-webkit-keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.imagesprofiles {
  border-radius: 100%;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: -0.5rem;
}

.imagesnext {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  max-width: 10rem;
}

.postinfo.relative {
  position: relative;
}

.createparty {
  width: 100%;
  height: 3rem;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  background-color: white;
  font-weight: 600;
  margin: 16px 0;
  margin-bottom: 2rem;
}

.showmore {
  background-color: #1f1f1f;
  color: white;
  border: none;
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 15px;
  font-size: 16px;
  margin-bottom: 1.3rem;
  margin-top: 7px;
}

.openpartyinputs {
  width: 100%;
  color: black;
  display: flex;
  background: white;
  justify-content: space-around;
  font-weight: 500;
  font-size: 20px;
  align-items: center;
  height: 3rem;
  margin: 10px 0;
  border-radius: 10px;
}

.loader {
  position: absolute;
  top: 35%;
  left: 35%;
  z-index: 1;
}

.profiledriverpost {
  border-radius: 100%;
  width: 56px;
  height: 56px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.profiledriverpost.big {
  width: 7rem;
  height: 7rem;
  transition: 0.5s;
}

.profiledriverpost.supermini {
  width: 1.3rem;
  height: 1.3rem;
  z-index: 0;
}

.profiledriverpost.friends {
  width: 5rem;
  height: 5rem;
}

.profiledriverpost.map {
  width: 30px;
  height: 30px;
  z-index: 0;
}

.profiledriverpost.seat {
  width: 48px;
  height: 48px;
  z-index: 0;
}

.profiledriverpost.guest {
  width: 64px;
  height: 64px;
}

.profiledriverpost.home {
  width: 35px;
  height: 35px;
}

.favoriteevent {
  height: 160px;
  width: 160px;
  transition: all 0.3s;
  -webkit-animation: loadingfavevent 2.5s infinite;
          animation: loadingfavevent 2.5s infinite;
  border-radius: 10px;
}

.favoriteevent.video {
  object-fit: cover;
}

.favoriteevent:active {
  transform: scale(0.9);
}

.drivepost.party.post {
  justify-content: space-between;
  height: 106px;
  padding: 0;
}

.credit {
  text-align: center;
  margin-bottom: -2rem;
  display: none;
}

.credit .firmname {
  margin: 0;
  font-size: 28px !important;
  text-align: center;
  font-size: 15px;
  background: -webkit-linear-gradient(0deg, orange, #fe4040);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.credit .spanfrom {
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: rgb(117, 117, 117);
  -webkit-text-fill-color: rgb(117, 117, 117);
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

/*
  Extremely fragile Google Maps Markers selectors.
    - z-index: 103 => Google Maps Markers layer
    - :has(img) => only marker containers
    - :not(...location) => don't apply to blue dot
*/
.gm-style div[style*="z-index: 103;"] img {
  border-radius: 200px;
  object-fit: cover;
}
.gm-style div[style*="z-index: 103;"] > div:has(> img) {
  overflow: visible !important;
}
.gm-style
  div[style*="z-index: 103;"]
  > div:has(> img:not([src*="location.svg"])):before {
  content: "";
  position: absolute;
  inset: -2px;
  border-radius: 200px;
  background: #eee;
}
.gm-style
  div[style*="z-index: 103;"]
  > div:has(> img:not([src*="location.svg"])):after {
  content: "";
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: -5px;
  transform: translate(-50%, 0%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #eee;
}

.gmnoprint div {
  background: none !important;
}

.status.party {
  color: #2fbd43;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  margin: 0;
}

.status.sleep {
  color: rgb(66, 132, 222);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  margin: 0;
}

.your {
  position: absolute;
  top: -25px;
  padding: 10px 25px;
  border-radius: 10px 10px 0 0;
  font-size: 13px !important;
  margin-top: 8px;
  color: white;
  background-color: #1f1f1f;
  width: 70px !important;
  max-width: 70px !important;
  min-width: 0 !important;
  white-space: nowrap;
  text-align: center;
  justify-content: center !important;
  align-items: center !important;
}

.your.event {
  position: absolute;
  top: -25px;
  padding: 10px 25px;
  border-radius: 10px 10px 0 0;
  font-size: 13px !important;
  margin-top: 8px;
  color: white;
  background-color: #1f1f1f;
  width: 70px !important;
  max-width: 70px !important;
  min-width: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.drivepost.party .partyicon img {
  border-radius: 10px !important;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.drivepost.party .partyicon video {
  border-radius: 10px !important;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.fitcount {
  background-color: #272727;
  padding: 10px 40px;
  border-radius: 20px;
  width: 60px;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completepartymemberdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 70px;
  position: relative;
  z-index: 1;
}

.partylabelguestlist {
  display: flex;
  align-items: center;
  justify-content: center;
}

.completepartymemberdiv.party {
  margin: 20px 0 !important;
}

.completepartymemberdiv span {
  text-align: center;
}

.partyguestlistlabel {
  color: white;
  background-color: transparent !important;
  width: 80%;
  max-width: 80%;
}

.partyguestlistlabel.second {
  max-width: 100%;
  width: 100%;
}

.partypostmembers {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.partymembercount {
  color: grey !important;
  font-size: 10px !important;
  font-weight: 600;
  z-index: 3;
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  align-items: center;
  justify-content: center;
}

.completepartymemberdiv p {
  margin: 5px 0;
  width: 100%;
  height: 26px;
  border-radius: 5px;
  background-color: #e0e0e0;
  margin-left: -7px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.clubowner {
  color: black;
  position: absolute;
  bottom: 16px;
  right: 2rem;
}

.clubshome {
  background-color: #000000;
  padding-bottom: 60px;
  margin-bottom: env(safe-area-inset-bottom);
}

.clubslogintitle {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
}

.clubshome h2 {
  margin: 0;
  margin-top: -50px;
  font-size: 21px;
  font-weight: 600;
}

.clubhomebuttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: #000000;
  margin: 0 30px;
  padding-top: 50px;
}

.clubhomebuttons a {
  border: none;
  background: linear-gradient(90deg, #fe4040, #fe7640);
  border-radius: 8px;
  text-align: center;
  padding: 12px 0;
  width: 335px;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  z-index: 3;
  margin: 5px 0;
  transition: all 0.6s;
}

.clubhomebuttons span {
  border: none;
  background: linear-gradient(90deg, #fe4040, #fe7640);
  border-radius: 8px;
  text-align: center;
  padding: 12px 0;
  width: 335px;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  z-index: 3;
  margin: 5px 0;
  transition: all 0.6s;
}

.clubhomebuttons span:nth-child(2) {
  background: transparent;
  color: #fd7f4d;
}

.clubshome .calloutimage {
  height: 5rem;
  margin-top: -4rem;
  margin-bottom: 16px;
  padding: 2rem;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calloutimage img {
  height: 5rem;
}

.clubshome p {
  text-align: center;
  color: #a5a5a5;
  font-size: 16px;
  line-height: 21px;
}

.businessheader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  background: linear-gradient(#fe4040, #fd6464);
  color: white;
  position: relative;
  margin-top: 2rem;
  font-size: 20px;
  border-radius: 0 20px 20px 0;
  width: 93%;
}

.businessheader a {
  position: absolute;
  left: 20px;
  top: 2.3rem;
  color: white;
}

.businesshead {
  background-color: #000000;
  color: white;
  width: 100%;
  height: 61px;
}

.businesshead h1 {
  font-size: 20px;
  font-weight: 600;
}

.businessblock {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
}

.businessblock img {
  width: 100%;
  max-width: 800px;
  height: 439px;
  object-fit: cover;
}

@media (min-width: 800px) {
  .businessblock img {
    margin: 25px 0;
    border-radius: 20px;
  }
  .businessblock p {
    max-width: 80% !important;
  }
}

.businessaddblock1 {
  padding: 2rem 0;
  width: 90%;
  margin: 16px auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 50px 1px rgba(0, 0, 0, 0.349);
}

.businessaddblock1 label {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 10px 0;
}

.businessaddblock1 label input {
  height: 2.2rem;
  width: 100%;
  font-size: 18px;
  border: none;
  border-bottom: 1px solid grey;
}

.businessCreate {
  border: none;
  background: linear-gradient(90deg, #fe4040, #fe7640);
  border-radius: 8px;
  text-align: center;
  padding: 12px 0;
  height: 50px;
  width: calc(100% - 45px);
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  z-index: 3;
  margin: 5px 0;
  transition: all 0.6s;
}

.businesswrapper > .businessCreate {
  margin-bottom: 100px;
}

.smapleclub {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}

.titlesamplediv {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sampletitle {
  margin: 16px;
  font-size: 26px;
}

.businessblockimage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}

.innerblock1 {
  width: 100%;
  height: 400px;
  background: rgba(128, 128, 128, 0.5);
  position: relative;
  background-size: cover;
  background-position: center;
}

.inneroverflowtop {
  width: 100%;
  height: 150px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.301), black);
}

.inneroverflowbottom {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, transparent, black, black);
}

.innerblock1 h2 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.businessupload {
  display: none;
}

.businessuploader {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.profilelinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
}

.profilelinks .postinfo {
  background-color: transparent;
}

.profilelinks .postinfo h2:hover {
  cursor: pointer;
}

.socialmediablock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 4.5rem;
  position: absolute;
  top: 10px;
  left: 10px;
}

.socialmediamodal {
  margin: 0 2rem;
  padding: 2rem 0;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 83%;
}

.clearicon {
  position: absolute;
  top: 10px;
  right: 15px;
}

.socialmediamodal img {
  margin-bottom: 16px;
}

.socialmediamodal h1 {
  font-size: 25px;
  text-align: center;
  margin: 0.5rem 0;
}

.socialmediamodal p {
  color: grey;
  text-align: center;
  margin: 0 16px;
  font-size: 15px;
}

.socialmediamodal input {
  margin-top: 1.5rem;
  border: 1px solid grey;
  width: 14rem;
  transition: 0.5s all;
  height: 2rem;
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
}

.socialmediamodal input:focus {
  box-shadow: 0 0 5pt 2pt #d3d3d3;
  outline-width: 0px;
}

.socialmediamodal button {
  border: none;
  background-color: transparent;
}

.socialmediamodal button h2 {
  color: #4380eb;
  font-size: 18px;
  font-weight: 600;
}

#instamodal.err {
  -webkit-animation: errmessage 0.5s;
          animation: errmessage 0.5s;
  width: 83%;
  margin: 0;
}

#snapmodal.err {
  -webkit-animation: errmessage 0.5s;
          animation: errmessage 0.5s;
  width: 83%;
  margin: 0;
}

@-webkit-keyframes errmessage {
  0% {
    margin-left: 0;
  }
  15% {
    margin-left: -1.5rem;
  }
  30% {
    margin-left: 1.5rem;
  }
  50% {
    margin-left: -16px;
  }
  75% {
    margin-left: 16px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes errmessage {
  0% {
    margin-left: 0;
  }
  15% {
    margin-left: -1.5rem;
  }
  30% {
    margin-left: 1.5rem;
  }
  50% {
    margin-left: -16px;
  }
  75% {
    margin-left: 16px;
  }
  100% {
    margin-left: 0;
  }
}

.searchmodal {
  width: 100%;
  background-color: #040000;
  position: relative;
  margin-bottom: env(safe-area-inset-bottom);
  padding-top: 0.5px;
}

.partymodalwrapper {
  min-height: 100vh;
  width: 100%;
  background-color: #040000;
  position: relative;
  margin-bottom: 20px;
  height: calc(100vh - env(safe-area-inset-top));
}

.partymodalinfo {
  min-height: 100%;
  width: 100%;
  background-color: #000000;
  position: relative;
  overflow-x: hidden;
}

.innerpartyinfo {
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.innerpartyinfo h2 {
  margin: 0;
  font-size: 25px;
  font-weight: 300;
}

.innerpartyinfo input {
  width: 100%;
  height: 2rem;
  width: 15rem;
  border-radius: 3px;
  border: none;
  transition: 0.5s all;
  font-size: 18px;
}

.innerpartyinfo input:focus {
  outline: none !important;
}

.innerpartyinfo label {
  margin: 16px 0;
}

.outsidelabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 60px;
  margin: 0 17px;
  margin-top: 0px;
  transform: scale(0);
}

.outsidelabel p {
  color: white;
  margin: 10px 0;
}

.outsidelabel input {
  height: 2rem;
  width: 100%;
  border-radius: 10px;
  border: none;
  transition: 0.5s all;
  font-size: 18px;
}

.outsidelabel.photo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  height: 50px;
  width: 50px;
  padding: 8px;
  border-radius: 100%;
  border: 2px solid #333232;
  border-style: dashed;
}

.partymodalheader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #1e1c1c;
  width: 100vw;
  position: relative;
  z-index: 20;
  background-color: black;
}

.headtitleparty {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #040000;
}

.headtitleparty .backlink {
  position: absolute;
  left: 20px;
  color: rgb(253, 127, 77);
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-left: -10px;
}

.backlink:hover {
  cursor: pointer;
}

.headtitleparty a {
  position: absolute;
  left: 20px;
  color: #fd7f4d;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-left: -10px;
}

.headtitleparty .partymodalheader a {
  margin-left: 16px;
  color: #4380eb;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.createeventinputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  margin: 0 25px;
}

.createeventinputs input {
  width: calc(100% - 15px);
  font-size: 16px;
  margin-bottom: 10px;
  color: #d6d6d6;
  background-color: #1f1f1f;
  border-radius: 10px;
  border: none;
  transition: 0.5s all;
  padding: 15px 0 15px 15px;
  transform: scale(0);
}

.createeventinputs textarea {
  width: calc(100% - 15px);
  font-size: 16px;
  margin-bottom: 10px;
  color: #d6d6d6;
  background-color: #1f1f1f;
  border-radius: 10px;
  border: none;
  transition: 0.5s all;
  line-height: 25px;
  padding-left: 15px;
  padding-top: 8px;
  resize: none;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
  transform: scale(0);
}

.createeventinputs textarea:focus {
  outline: none;
}

.partymodalheader h1 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.upperpartycontainer {
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 100%;
  margin-top: 80px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
  padding-left: 20px;
}

.upperpartycontainer input {
  width: 15rem;
  border-radius: 3px;
  border: none;
  transition: 0.5s all;
  font-size: 18px;
  margin-left: 20px;
  height: 100%;
}

.partymodalheader button {
  border: none;
  color: #fd7f4d;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
}

.partymodalheader button:hover {
  cursor: pointer;
}

.partymodalheader button:nth-child(1) {
  position: absolute;
  left: 20px;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-left: -10px;
}

.partymodalheader button:nth-child(3) {
  position: absolute;
  right: 20px;
}

.poster {
  position: absolute;
  right: 20px;
  border: none;
  color: #fd7f4d;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
}

.poster:hover {
  cursor: pointer;
}

.animationtitle {
  -webkit-animation: swipetwo 0.5s forwards;
          animation: swipetwo 0.5s forwards;
}

.buttonanimation {
  -webkit-animation: backbutton 0.5s forwards ease;
          animation: backbutton 0.5s forwards ease;
}

@-webkit-keyframes backbutton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes backbutton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes swipetwo {
  0% {
    opacity: 0;
    margin-right: -500px;
  }
  100% {
    opacity: 1;
    margin-right: 0;
  }
}

@keyframes swipetwo {
  0% {
    opacity: 0;
    margin-right: -500px;
  }
  100% {
    opacity: 1;
    margin-right: 0;
  }
}

.partyform {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.partyform label {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.postedit {
  background-color: transparent;
  color: #4380eb;
  font-size: 17px;
  border: none;
  border-radius: 100%;
  height: 2.2rem;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
}

.postedit.update {
  right: 23px;
}

.postedit.private {
  left: 20px;
  margin: 0;
  right: inherit;
  color: #7b7f85;
  font-weight: 600;
  font-size: 14px;
}

.editpostinput {
  border: none;
  padding-bottom: 10px;
  margin: 10px 0;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
  color: #33363d;
  width: 80%;
}

.whiteline {
  background-color: whitesmoke;
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 100%;
  bottom: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteline > span {
  width: 18px;
  height: 18px;
}

.covidstatus {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FriendsTab {
  transition: 1s all;
}

.businesswrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #000000;
}

.innerbusinesswrapperdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.innerbusinesswrapperinputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.innerbusinesswrapperdiv h2 {
  color: white;
  margin: 0;
  margin-left: 21px;
  font-size: 21px;
}

.innerbusinesswrapperinputs input {
  width: calc(100% - 65px);
  color: white;
  height: 45px;
  background-color: #1f1f1f;
  border: none;
  border-radius: 10px;
  margin: 5px 0;
  padding-left: 20px;
  font-size: 17px;
}

.innerbusinesswrapperinputs input::-webkit-input-placeholder {
  color: #888888;
}

.innerbusinesswrapperinputs input:-ms-input-placeholder {
  color: #888888;
}

.innerbusinesswrapperinputs input::-ms-input-placeholder {
  color: #888888;
}

.innerbusinesswrapperinputs input::placeholder {
  color: #888888;
}

.innerbusinesswrapperinputs textarea {
  width: calc(100% - 65px);
  color: white;
  background-color: #1f1f1f;
  border: none;
  border-radius: 10px;
  margin: 5px 0;
  padding-left: 20px;
  padding-top: 10px;
  resize: none;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
  font-size: 17px;
}

.innerbusinesswrapperinputs textarea:focus {
  outline: none;
}

.languagebox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #1f1f1f;
  padding-bottom: 20px;
}

.languageboxsingle {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1f1f1f;
}

.languageboxsingle p {
  font-size: 16px;
  color: white;
  margin-left: 30px;
  font-weight: 500;
}

.languageboxsingle:hover {
  cursor: pointer;
}

.languagehead {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.languagehead h2 {
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.codename {
  color: #d6d6d6;
  font-size: 16px !important;
  height: auto !important;
  width: calc(100% - 20px) !important;
  margin: 10px 0;
  background-color: #1f1f1f;
  border-radius: 10px;
  border: none;
  transition: 0.5s;
  padding: 15px 0 15px 15px;
  /* font-family: "Libre Barcode 128", cursive; */
}

.codename::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  /* font-family: "Fredoka", sans-serif; */
}

.codename:-ms-input-placeholder {
  font-family: "Roboto", sans-serif;
  /* font-family: "Fredoka", sans-serif; */
}

.codename::-ms-input-placeholder {
  font-family: "Roboto", sans-serif;
  /* font-family: "Fredoka", sans-serif; */
}

.codename::placeholder {
  font-family: "Roboto", sans-serif;
  /* font-family: "Fredoka", sans-serif; */
}

/* #thesecretcodeentry {
  font-family: "Libre Barcode 128", cursive;
} */

#thesecretcodeentry::-webkit-input-placeholder {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
}

#thesecretcodeentry:-ms-input-placeholder {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
}

#thesecretcodeentry::-ms-input-placeholder {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
}

#thesecretcodeentry::placeholder {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Fredoka", sans-serif;
}

.messageholder {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.creatormessage {
  width: 85%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #dcdbdd;
  background-color: #e9e9e9b6;
  color: grey;
  transform: scale(0.8, 0.8);
  transition: 0.5s;
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 10px;
}

.creatormessage.active {
  transform: scale(1, 1);
  border: 1px solid #c7c4c9;
  background-color: #ece9f0c2;
  color: black;
  z-index: 3;
}

.creatormessage p {
  text-align: center;
  font-size: 19px;
  line-height: 24px;
}

.checkboxprivate {
  -webkit-appearance: none;
  background-color: #f1ecec;
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.checkboxprivate:checked {
  background: #fd7f4d;
  color: #99a1a7;
}

.regular-checkbox:checked:after {
  content: "/2714";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #99a1a7;
}

.suggestions {
  transition: 1s;
  width: calc(100% - 45px);
}

.suggestions.driver .suggestionsingle:nth-child(1) {
  margin-top: 0;
}

.suggestions.driver .suggestionsingle:last-child {
  margin-bottom: 10px;
}

.suggestionsingle {
  border-radius: 10px;
  margin: 5px 0;
  max-width: 100%;
  color: white;
  /* background-color: #1f1f1f; */
  min-height: 45px;
  line-height: 21px;
  padding: 3px 0;
}

.suggestionsingle p {
  font-weight: 500;
}

.suggestionsingle:hover {
  cursor: pointer;
}

.suggestiontext {
  padding: 2px 0;
  margin: 10px;
  font-weight: 300;
}

.mapsheader {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  height: 120px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 30px 30px;
  z-index: 2;
}

.addressclubfinder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 0 30px;
}

.addressclubfinder p {
  margin: 5px 0;
  word-break: break-word;
  transition: 0.5s;
  border-radius: 5px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 400;
  box-shadow: rgba(240, 101, 46, 0.4) 5px 5px, rgba(240, 133, 46, 0.3) 10px 10px,
    rgba(240, 162, 46, 0.2) 15px 15px, rgba(240, 188, 46, 0.1) 20px 20px,
    rgba(240, 46, 46, 0.05) 25px 25px;
}

.suggestionloader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.no_event {
  width: 100%;
  height: 100px;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1f1f1f;
  color: #575757;
}

.no_event.friends {
  background-color: rgb(23, 23, 23);
  width: calc(100% - 30px);
}

.mapsettingbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 70px;
}

.mapelement {
  margin-left: 20px;
  height: 30px;
  width: 30px;
  padding: 10px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.356);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapelement2 {
  margin-right: 20px;
  height: 30px;
  width: 30px;
  padding: 10px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.356);
  display: flex;
  align-items: center;
  justify-content: center;
}

.DrivePostWrapper {
  background-color: black;
  min-height: 100vh;
  overflow-x: hidden;
}

.overflow {
  background: linear-gradient(180deg, transparent, black, black);
  height: 160px;
  width: 100%;
  margin-top: -75px;
  position: relative;
}

.overflowtop {
  background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.301), black);
  height: 150px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
}

.DrivePostHeader {
  background: url(/static/media/postbackground.80be5a0f.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  position: relative;
}

.DrivePostHeader .postheaderbackicon {
  position: absolute;
  top: 16px;
  left: 16px;
  color: white;
  background-color: #2a2a2ab4;
  border-radius: 100%;
  padding: 11px;
  z-index: 11;
}

.eventpostvideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.postheaderbackicon {
  position: absolute;
  top: 16px;
  left: 16px;
  color: white;
  background-color: #2a2a2ab4;
  border-radius: 100%;
  padding: 11px;
  z-index: 11;
}

.DrivePostHeader.Event {
  background-position: center;
}

.DrivePostBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: black;
  padding: 0 20px;
  margin-bottom: calc(env(safe-area-inset-bottom) + 10px);
  max-width: 100%;
  position: relative;
}

.privatestatus {
  color: #a5a5a5;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  right: 30px;
  top: 27px;
}

.DrivePostBodyName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0;
}

.DrivePostBodyName h1 {
  margin: 0;
  font-size: 16px;
  color: white;
  word-break: break-word;
}

.DrivePostBodyName h2 {
  color: #a5a5a5;
  margin: 0;
  font-weight: 400;
  margin-top: 3px;
  font-size: 14px;
}

.DrivePostBodyMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.DrivePostBodyMain h1 {
  color: white;
  font-size: 34px;
  font-weight: 400;
  line-height: 32px;
  margin: 6px 0;
}

.DrivePostBodyMain h2 {
  color: white;
  font-size: 21px;
  font-weight: 400;
  line-height: 17px;
  margin: 0;
}

.DrivePostBodyMain h3 {
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 15px 0;
  word-break: break-word;
}

.eventhostedby {
  display: flex;
  align-items: center;
}

.eventhostedby p {
  font-size: 18px;
  line-height: 24px;
  color: #a5a5a5;
  margin-left: 10px;
}

.eventphonecall {
  color: white;
  position: absolute;
  top: 0;
  right: 65px;
  z-index: 10;
}

.eventphonecall:hover {
  cursor: pointer;
}

.savedmodal {
  color: white;
  background-color: #262626;
  padding: 15px 30px;
  border-radius: 10px;
  font-weight: 600;
  outline: none;
}

.eventfavourite {
  color: white;
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 10;
  transition: all 0.2s;
}

.eventfavourite:hover {
  cursor: pointer;
}

.eventfavourite:active {
  transform: scale(0.8);
}

.DrivePostFromTo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.DrivePostFromTo img {
  height: 74px;
}

.DrivePostFromTo p {
  color: #d6d6d6;
  background: #1f1f1f;
  margin: 5px 0;
  width: 95%;
  min-height: 45px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 500;
  word-break: break-word;
}

.DrivePostFromTo p:nth-child(2) {
  margin-bottom: 10px;
}

.DrivePostInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DrivePostEvent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.DrivePostEvent p {
  min-height: 45px;
  background-color: #1f1f1f;
  color: #a5a5a5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  word-break: break-word;
}

.DrivePostEvent p:nth-child(1) {
  width: 100%;
}

.DrivePostEvent p:nth-child(2) {
  width: 100%;
  margin-left: 10px;
}

.DrivePostEvent.Info {
  margin-top: 10px;
}

.DrivePostEvent.Info p {
  color: #a5a5a5;
  font-size: 18px;
  line-height: 23px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding: 5px;
  word-break: break-word;
}

.PostListGuest {
  width: 100%;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.no_one_yet {
  width: 90%;
  height: 200px;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #575757;
}

.no_views {
  height: 100px;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #575757;
}

/* @media (max-width: 480px) {
  .no_one_yet {
    margin-top: 25%;
  }
} */

.GuestListHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #262626;
  width: 100%;
  padding: 10px 0;
  z-index: 10;
  border-bottom: 1px solid #2f2f2f;
  border-radius: 20px 20px 0 0;
  margin-top: -2px;
}

.GuestListHead h1 {
  font-size: 18px;
  color: #fafafa;
  margin: 0;
  margin-top: 35px;
  margin-bottom: 5px;
}

.swipeline {
  background-color: #555555;
  height: 5px;
  width: 45px;
  border-radius: 20px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 12px;
  left: 50%;
}

.head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  height: 50px;
  background-color: #fe4040;
  border-bottom: 1px solid white;
  display: flex;
  align-items: center;
}

.head h1 {
  margin: 0;
  font-size: 20px;
  margin-left: 20px;
  color: white;
}

.seatlistsingle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.posteditbutton {
  background-color: #2a2a2ab4;
  color: white;
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 11;
  border-radius: 50px;
  padding: 0.6rem;
  height: 47px;
  overflow: hidden;
  transition: 0.5s;
  -webkit-animation: postedit 6s forwards;
          animation: postedit 6s forwards;
}

@-webkit-keyframes postedit {
  0% {
    width: 47px;
  }
  35% {
    width: 47px;
  }
  50% {
    width: 90px;
  }
  85% {
    width: 90px;
  }
  100% {
    width: 47px;
  }
}

@keyframes postedit {
  0% {
    width: 47px;
  }
  35% {
    width: 47px;
  }
  50% {
    width: 90px;
  }
  85% {
    width: 90px;
  }
  100% {
    width: 47px;
  }
}

.editspan {
  transition: 0.5s;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translate(50%, -50%);
  -webkit-animation: posteditspan 4.5s ease forwards;
          animation: posteditspan 4.5s ease forwards;
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
  opacity: 0;
}

@-webkit-keyframes posteditspan {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes posteditspan {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.posteditbutton:hover {
  cursor: pointer;
}

.partytitlewrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eventtags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  overflow-x: scroll;
  min-width: 100vw;
  margin-left: -20px;
}

.eventtags span {
  height: 30px;
  background-color: #27292d;
  border-radius: 20px;
  padding: 3px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  margin: 0 4px;
  font-size: 16px;
}

.eventtags span:first-child {
  margin-left: 20px;
}

.eventtags span:last-child {
  margin-right: 40px;
}

.partypostslider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: scroll;
  margin: 20px 0;
}

.partypostslider::-webkit-scrollbar {
  display: none;
}

.partypostslidersingle.userpage {
  width: 300px;
  min-width: 300px;
  margin: 0 10px;
}

.partypostslidersingle.userpage:first-child {
  margin-left: 20px;
}

.partypostslidersingle.userpage:last-child {
  margin-right: 20px;
}

.partypostslidersingle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.3s;
  width: 100%;
  height: 180px;
  border-radius: 20px;
  margin-bottom: 15px;
  background-position: center;
  background-size: cover;
  position: relative;
  background-color: #1f1f1f;
}

.partypostslidersingle h1 {
  margin: 15px;
  font-size: 18px;
  color: white;
}

.partypostslidersingle p {
  margin: 15px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  word-break: break-word;
  max-width: 166px;
}

.partypostslidersingle p > span {
  font-size: 16px;
  font-weight: 500;
  color: lightgrey;
}

.partypostslidersingle .promoprice {
  position: absolute;
  bottom: 0px;
  right: 15px;
  color: white;
  border: 1px solid lightgrey;
  border-radius: 20px;
  padding: 9px 16px;
  font-weight: 500;
  font-size: 16px;
}

.partypostslidersingle:active {
  transform: scale(0.97);
}

.partypostslidersingle video:active {
  transform: scale(0.97);
}

.viewbutton {
  background-color: #e0e0e0;
  border-radius: 5px;
  height: 26px;
  min-width: 70px !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 13px;
  margin: 0;
  margin-right: 20px !important;
}

.viewbutton.analytics {
  margin-right: 0 !important;
  color: white !important;
  height: auto;
  line-height: 8px;
  background-color: transparent;
  width: auto !important;
  min-width: auto !important;
}

.promotionmain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.promotionhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 21px;
  width: calc(100% - 50px);
}

.promotionhead h2 {
  color: #888888;
  font-size: 15px;
  margin: 0;
}

.promotionhead span {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 5px;
  transition: 0.5s;
}

.promotionhead span a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.promotionposts {
  margin-top: 10px;
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: calc(env(safe-area-inset-bottom) + 30px);
}

.promotionlink {
  min-height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #1f1f1f;
  border-radius: 20px;
  margin: 8px 0;
  padding: 11px;
  transition: 1s;
  opacity: 1;
  border: 1px solid transparent;
}

.promotionlink:active {
  border: 1px solid #fd7f4d;
}

.promotionlinkimage img {
  border-radius: 10px !important;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.promotionlinkimage video {
  border-radius: 10px !important;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.promotionlink p {
  color: white;
  font-size: 13px;
  margin-right: 30px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  max-width: 123px;
  width: 123px;
}

.promotionlink p span {
  font-size: 10px;
  word-break: break-word;
  line-height: 12px;
  color: #8c8c8c;
}

.locationspan {
  max-width: 100% !important;
}

.scaleanimation {
  -webkit-animation: startscaleanimation 0.9s forwards;
          animation: startscaleanimation 0.9s forwards;
}

.scaleanimationinput {
  -webkit-animation: startscaleanimationinput 1.1s forwards;
          animation: startscaleanimationinput 1.1s forwards;
}

.scaleanimationinputsmall {
  -webkit-animation: startscaleanimationinputsmall 0.9s forwards;
          animation: startscaleanimationinputsmall 0.9s forwards;
}

@-webkit-keyframes startscaleanimation {
  0% {
    transform: scale(0.7);
  }
  35% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes startscaleanimation {
  0% {
    transform: scale(0.7);
  }
  35% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes startscaleanimationinput {
  0% {
    transform: scale(0);
  }
  35% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes startscaleanimationinput {
  0% {
    transform: scale(0);
  }
  35% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes startscaleanimationinputsmall {
  0% {
    transform: scale(0);
  }
  35% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes startscaleanimationinputsmall {
  0% {
    transform: scale(0);
  }
  35% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.modalwarning {
  padding: 2px 20px;
  outline: none;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  background-color: #262626;
}

.editdrawer {
  height: 100vh;
  background-color: black;
  overflow: hidden;
}

.promotionbalance {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #1f1f1f;
  color: #a7abb3;
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translate(0%, -50%);
}

.successcontainer {
  height: 850px;
  max-height: 95vh;
  width: 400px;
  max-width: 400px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

@media (max-width: 500px) {
  .successcontainer {
    width: 100%;
    max-width: none;
    height: 100%;
    border-radius: 0;
    max-height: none;
  }
}

.successcontainer img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}

.successmain {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.successmain h1 {
  font-style: italic;
  font-family: "Staatliches", cursive;
  line-height: 36px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  width: 100%;
}

.successmain h1 .spantitle {
  color: #00affe;
  font-size: 50px;
  font-weight: 600;
  width: 100%;
  line-height: 36px;
  position: absolute;
  left: -60%;
  transform: translateX(-50%);
  -webkit-animation: 1s successh1 forwards;
          animation: 1s successh1 forwards;
}

.successmain h1 .spantitle.driveposttitlenormal {
  color: #fe0004d7;
  background: inherit;
  text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.4);
  z-index: 2;
  left: 0;
  transform: translateX(0%) rotateX(90deg);
  -webkit-animation: .5s spandrivepost forwards;
          animation: .5s spandrivepost forwards;
  -webkit-animation-delay: .5s;
          animation-delay: .5s;
}

@-webkit-keyframes spandrivepost{
  from {
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0);
  }
}

@keyframes spandrivepost{
  from {
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0);
  }
}

.successmain h1 .spantitle.driveposttitle.bottomone {
  -webkit-animation: .5s readytodrivebottom forwards;
          animation: .5s readytodrivebottom forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0;
  left: 50%;
  bottom: 46%;
  z-index: 1;
  background: rgba(255, 255, 255, 0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-shadow: none;
  text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.58);
}

.successmain h1 .spantitle.driveposttitle.bottomtwo {
  -webkit-animation: .5s readytodrivebottomtwo forwards;
          animation: .5s readytodrivebottomtwo forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0;
  left: 50%;
  bottom: 46%;
  z-index: -1;
  background: rgba(255, 255, 255, 0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-shadow: none;
}

.successmain h1 .spantitle.driveposttitle.topone {
  -webkit-animation: .5s readytodrivetop forwards;
          animation: .5s readytodrivetop forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0;
  left: 50%;
  top: 50%;
  z-index: 1;
  background: rgba(255, 255, 255, 0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-shadow: none;
  text-shadow: 5px 5px 7px rgba(0, 0, 0, 0.58);
}

.successmain h1 .spantitle.driveposttitle.toptwo {
  -webkit-animation: .5s readytodrivetoptwo forwards;
          animation: .5s readytodrivetoptwo forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0;
  left: 50%;
  top: 50%;
  z-index: -1;
  background: rgba(255, 255, 255, 0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-shadow: none;
}

@-webkit-keyframes readytodrivebottom {
  from {
    opacity: .5;
  }
  to {
    bottom: 43%;
    opacity: .6;
  }
}

@keyframes readytodrivebottom {
  from {
    opacity: .5;
  }
  to {
    bottom: 43%;
    opacity: .6;
  }
}

@-webkit-keyframes readytodrivebottomtwo {
  from {
    opacity: .4;
  }
  to {
    bottom: 40%;
    opacity: .4;
  }
}

@keyframes readytodrivebottomtwo {
  from {
    opacity: .4;
  }
  to {
    bottom: 40%;
    opacity: .4;
  }
}

@-webkit-keyframes readytodrivetop {
  from {
    opacity: .5;
  }
  to {
    top: 47%;
    opacity: .6;
  }
}

@keyframes readytodrivetop {
  from {
    opacity: .5;
  }
  to {
    top: 47%;
    opacity: .6;
  }
}

@-webkit-keyframes readytodrivetoptwo {
  from {
    opacity: .4;
  }
  to {
    top: 44%;
    opacity: .4;
  }
}

@keyframes readytodrivetoptwo {
  from {
    opacity: .4;
  }
  to {
    top: 44%;
    opacity: .4;
  }
}


.successmain.drivepost {
  background: url(/static/media/postbackground.80be5a0f.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  border-radius: 0;
}

@-webkit-keyframes successh1 {
  from {
    left: -60%;
  }
  to {
    left: 50%;
  }
}

@keyframes successh1 {
  from {
    left: -60%;
  }
  to {
    left: 50%;
  }
}

.successmain h1 .secondspan {
  font-size: 30px;
  color: white;
  position: absolute;
  right: -60%;
  transform: translateX(50%);
  -webkit-animation: 0.8s successspan forwards;
          animation: 0.8s successspan forwards;
  overflow: hidden;
  padding-right: 5px;
  margin-right: -5px;
}

@-webkit-keyframes successspan {
  from {
    right: -60%;
  }
  to {
    right: 50%;
  }
}

@keyframes successspan {
  from {
    right: -60%;
  }
  to {
    right: 50%;
  }
}

.successmain .successbutton {
  border: none;
  background: linear-gradient(90deg, #fe4040, #fe7640);
  border-radius: 8px;
  text-align: center;
  padding: 12px 0;
  width: 90%;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
  z-index: 3;
  transition: all 0.6s;
  position: absolute;
  bottom: 30px;
}

.successmain .successbutton:hover {
  cursor: pointer;
  transform: scale(0.95);
}

.successmain .successbutton:hover {
  cursor: pointer;
}

.successmain .tag {
  font-size: 20px;
  color: white;
  font-style: italic;
  font-family: "Staatliches", cursive;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  margin-top: 40px;
}

.successbottom {
  position: absolute;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
}

.successbottom span {
  height: 2px;
  border-radius: 20px;
  width: 90%;
  position: absolute;
  background-color: white;
}

.chatroomwrapper {
  width: 100%;
  flex: 1 1;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}

.chatroomheader {
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #181818;
  position: relative;
}

.chatroomheader p {
  color: white;
  font-size: 19px;
  font-weight: 600;
  margin: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.chatroommain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/large.837fc2a8.png);
  background-size: cover;
  background-position: center;
  height: calc(
    100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 60px - 85px
  );
  overflow-y: scroll;
}

.chatroommain::-webkit-scrollbar {
  display: none;
}

.chatroommain ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
}

.chatroommain ul li {
  color: white;
  list-style: none;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.singlemessagewrapper {
  max-width: 70%;
  margin: 7px 15px;
  word-break: break-word;
}

.singlemessage {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: white;
  width: 100%;
  position: relative;
}

.singlemessage p {
  margin: 0;
  margin-left: 10px;
  padding: 15px;
  border-radius: 15px;
  max-width: 100%;
  background-color: #1f1f1f;
}

.singlemessage p span {
  color: grey;
  font-size: 12px;
  width: 100%;
  display: flex;
  margin-top: 5px;
}

.messagetextwrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.messagename {
  color: grey;
  font-size: 10px;
  margin: 3px 0;
  margin-left: 12px;
}

.messagebackicon:hover {
  cursor: pointer;
}

.deletemodal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #262626;
  height: auto;
  width: 75%;
  max-width: 450px;
  padding: 30px 0;
  border-radius: 20px;
}

.deletemodal img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 15px;
}

.deletemodal p {
  margin: 20px 0;
  color: white;
  font-size: 18px;
  font-weight: 500;
  font-family: "Fredoka", sans-serif;
  text-align: center;
  max-width: 85%;
}

.deletemodal .stopdelete {
  color: rgb(142, 142, 142);
}

.deletemodal .stopdelete:hover {
  cursor: pointer;
}

.deletemodal button {
  width: 200px;
  padding: 12px 0;
  border-radius: 20px;
  color: white;
  border: none;
  font-size: 15px;
  background: linear-gradient(90deg, #fe4040, #fe7640);
  font-weight: 600;
}

.deletemodal button:hover {
  cursor: pointer;
}

.mutualfriends {
  font-size: 12px !important;
  color: grey;
}

.userprofilewrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  margin-bottom: calc(env(safe-area-inset-bottom) + 30px);
}

.userprofileheader {
  height: 200px;
  width: 100%;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.userprofilemain {
  width: 100%;
  max-width: 100%;
  min-height: 70vh;
  background-color: black;
  border-radius: 0 0 25px  25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 20px;
  margin-top: -5px;
  margin-bottom: 60px;
}

.userprofilemainheader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  position: absolute;
  top: -65px;
}

.userprofilemainheader span {
  border-radius: 100%;
  padding: 5px;
  border: none;
  background-color: #1f1f1f;
  height: 7rem;
  width: 7rem;
  transition: all 0.3s;
}

.userprofilemainheader span:active {
  transform: scale(0.9);
}

.userprofileinfoblock {
  width: 90%;
  margin-top: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userprofileinfoblock h2 {
  color: white;
  margin: 10px 0;
  word-wrap: break-word;
  word-break: break-all;
  text-align: left;
  font-size: 25px;
  font-family: "Roboto", sans-serif;
}

.userprofileinfoblock p {
  font-family: "Roboto", sans-serif;
}

.userprofilesocials {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

.usersocialcount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.usersocialcount p {
  margin: 15px 10px;
  color: white;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.usersocialcount p span {
  font-weight: 400;
  color: #727476;
}

.usersocialcount p:first-child {
  margin-left: 0;
}

.userprofilesocials span {
  padding: 0;
  background: none;
  border: none;
  height: 30px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userprofilesocials span:first-child {
  margin-left: 0;
}

.userprofilesocials span a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
}

.userprofiledivider {
  width: 100%;
  height: 10px;
  background-color: rgb(23, 23, 23);
  margin: 20px 0;
}

.usersmutualfriends {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userfavourites {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.usermutualfriendsheader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.usermutualfriendsheader p {
  color: white;
  margin: 10px 0px;
  font-weight: 600;
  margin-left: 20px;
  text-transform: capitalize;
  font-size: 20px;
}

.usermutualfriendsheader p:nth-child(2) {
  color: #fe7640;
  font-weight: 400;
}

.usermutualfriendsmapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  margin-top: 5px;
}

.usermutualfriendsmapper::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.singlemutualfriend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  min-height: 120px;
  margin: 10px 0;
}

.singlemutualfriend:first-child {
  margin-left: 20px;
}

.singlemutualfriend.event:first-child {
  margin-left: 20px;
}

.singlemutualfriend.event:last-child {
  padding-right: 20px;
}

.singlemutualfriend.event {
  align-items: flex-start;
  margin-left: 15px;
  min-width: auto;
  min-height: auto;
}

.singlemutualfriend h2 {
  margin: 5px 0;
  font-size: 16px;
  color: white;
}

.userprofileoptions {
  position: absolute;
  top: 16px;
  right: 16px;
  color: white;
  background-color: #2a2a2ab4;
  border-radius: 100%;
  padding: 11px;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitebuttondiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewsheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top: 10px;
}

.viewsheader p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  margin: 10px 0;
}

.morepeoplewrapper {
  margin-top: 10px;
  width: 100%;
}

@-webkit-keyframes fadeInEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.morepeople {
  opacity: 0;
  -webkit-animation: fadeInEffect 0.8s forwards;
          animation: fadeInEffect 0.8s forwards;
  width: 100%;
  border-radius: 10px;
  height: 80px;
  background: linear-gradient(
      rgba(0, 0, 0, 0.85),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.6)
    ),
    url(/static/media/morepeople.4e426555.webp);
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.morepeople p {
  margin: 0;
  margin-left: 15px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
}

.profilesettingsbutton {
  position: absolute;
  top: calc(env(safe-area-inset-top) + 16px);
  right: 16px;
  color: white;
  z-index: 99;
  background-color: #2a2a2ab4;
  border-radius: 100%;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingspagewrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.settingsectiontitle {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 10px;
}

.mainsettingsection {
  width: calc(100% - 40px);
  margin-bottom: calc(env(safe-area-inset-bottom) + 30px);
}

.settingsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.singleinnersetting {
  width: calc(100% - 20px);
  padding: 5px 10px;
  background-color: #1f1f1f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #333333;
}

.singleinnersetting:last-child {
  border-bottom: none;
}

.singleinnersetting p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #9e9d9e;
}

.singleinnersetting h3 {
  margin: 0;
  color: #dfe0e0;
  font-size: 18px;
  font-weight: 400;
}

.changeusernamewrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-bottom: env(safe-area-inset-bottom);
}

.changeusernamemain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 40px);
  margin: 30px 0;
  color: white;
  position: relative;
  height: 100%;
}

.changeusernamemain h2 {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
}

.changeusernamemain p {
  color: #9e9d9e;
  margin: 10px 0;
}

.changeusernamemain input {
  width: calc(100% - 10px);
  margin: 10px 0;
  padding: 10px 5px;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #9e9d9e;
  border-bottom: 2px solid rgb(48, 47, 47);
}

.changeusernamemain textarea {
  margin-top: 10px;
  width: calc(100% - 20px);
  padding: 10px;
  background-color: #1f1f1f;
  border: none;
  border-radius: 10px;
  resize: none;
  font-family: "Fredoka", sans-serif;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.changeusernamemain textarea::-webkit-input-placeholder {
  font-family: "Fredoka", sans-serif;
}

.changeusernamemain textarea:-ms-input-placeholder {
  font-family: "Fredoka", sans-serif;
}

.changeusernamemain textarea::-ms-input-placeholder {
  font-family: "Fredoka", sans-serif;
}

.changeusernamemain textarea::placeholder {
  font-family: "Fredoka", sans-serif;
}

.changeusernamemain textarea:focus {
  outline: none;
}

.innerinformationbug {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.innerinformationbug p {
  text-align: center;
  margin-bottom: 15px;
}

.innerinformationbug button {
  width: 70%;
  padding: 12px 0;
  border-radius: 50px;
  border: none;
  background-color: #1f1f1f;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.timersetwrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.switch {
  width: 45px;
  height: 28px;
  border-radius: 50px;
  position: relative;
  transition: all 0.3s;
}

.switchcircle {
  background-color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.privatepartysection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  opacity: 0;
  -webkit-animation: fadeInEffect 0.8s forwards;
          animation: fadeInEffect 0.8s forwards;
}

.privatepartysection h3 {
  color: white;
  font-weight: normal;
  margin: 0;
}

.mainfavouritesection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: calc(env(safe-area-inset-bottom) + 100px);
}

.favouritemap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  height: 100%;
  margin-top: 5px;
}

.singleFavourite {
  width: 175px;
  max-width: 175px;
  height: 175px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 5px;
  transition: all 0.3s;
  position: relative;
  -webkit-animation: loadingfavevent 2.5s infinite;
          animation: loadingfavevent 2.5s infinite;
}

@-webkit-keyframes loadingfavevent {
  0% {
    background-color: rgba(128, 128, 128, 0.421);
  }
  50% {
    background-color: rgba(128, 128, 128, 0.255);
  }
  100% {
    background-color: rgba(128, 128, 128, 0.421);
  }
}

@keyframes loadingfavevent {
  0% {
    background-color: rgba(128, 128, 128, 0.421);
  }
  50% {
    background-color: rgba(128, 128, 128, 0.255);
  }
  100% {
    background-color: rgba(128, 128, 128, 0.421);
  }
}

.singleFavourite h2 {
  color: white;
  font-weight: 600;
  font-size: 23px;
  line-height: 23px;
  margin: 5px;
  z-index: 1;
}

.singleFavourite:active {
  transform: scale(0.95);
}

.singleFavourite video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.suggestedwrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
}

.suggestedwrapper::-webkit-scrollbar {
  display: none;
}

.singlesuggested {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #121212;
  margin: 0 5px;
  padding: 12px 0;
  border-radius: 5px;
  border: 1px solid rgb(31, 31, 31);
  width: 150px;
}

.singlesuggested:first-child {
  margin-left: 0;
}

.singlesuggested h2 {
  color: white;
  font-size: 14px;
  margin: 10px 0;
}

.dotspan {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.previewwrapper {
  height: 80vh;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  outline: none;
  overflow: hidden;
}

.profilepicturewrapper {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgb(183, 183, 183), rgb(83, 83, 83));
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  outline: none;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: hidden;
}

.profilepicturewrapper p {
  color: white;
  margin: 15px 20px;
  padding: 0;
  font-weight: 600;
  font-size: 28px;
  max-width: 100%;
  word-break: break-all;
}

@media (min-width: 900px) {
  .previewwrapper {
    height: 600px;
    width: 350px;
  }
}

.previewcloser {
  width: 100%;
  background-color: transparent;
  color: white;
  font-weight: 600;
  font-size: 18px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px 0;
}

.addedguests {
  width: 100%;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  margin-bottom: 10px;
}

.addedguests::-webkit-scrollbar {
  display: none;
}

.singleguestpreview {
  position: relative;
  margin: 0 8px;
  -webkit-animation: prevAni 0.3s both;
          animation: prevAni 0.3s both;
}

@-webkit-keyframes prevAni {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes prevAni {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.mapcontentbutton {
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  max-width: 500px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mapcontentbutton span {
  width: 100%;
  background-color: #1f1f1f;
  color: white;
  border-radius: 20px;
  margin: 0;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.mapcontentbutton span:nth-child(1) {
  border-radius: 50px 0 0 50px;
}

.mapcontentbutton span:nth-child(2) {
  border-radius: 0 50px 50px 0;
}

.shopwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.shopwrapper h2 {
  color: #ff4f8f;
  margin-top: 25px;
  font-size: 16px;
  background-color: #333333;
  padding: 5px 15px;
  border-radius: 5px;
  transition: all 0.5s;
}

.paymentpackage {
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paymentpackage img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  margin: 5px 0;
}

.paymentpackage h2 {
  color: white;
  background-color: transparent;
  font-size: 26px;
  margin: 5px 0;
}

.paymentpackage p {
  color: white;
  background-color: transparent;
  font-size: 15px;
  max-width: 70%;
  margin: 0;
  text-align: center;
}

.paymentbutton {
  width: 90%;
  margin: 20px 0;
  padding: 10px 0;
  border-radius: 50px;
  color: white;
  border: none;
  background-color: #ff4f8f;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
}

.paymentbutton.free {
  background: linear-gradient(90deg, #fe4040, #fe7640);
}

.paymentbutton > span {
  font-size: 14px;
  font-weight: normal;
}

.paymentpackage .declinebutton {
  color: #aaaaaa;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 25px;
}

.paymentidicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.paymentidicator > span {
  height: 8px;
  width: 8px;
  background-color: grey;
  border-radius: 100%;
  margin: 4px;
}

.tutorialdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background: rgb(38, 38, 38);
}

.tutorialhead {
  width: 100%;
  height: 70px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #aaaaaa;
  margin-top: env(safe-area-inset-top);
}

.tutorialdiv h2 {
  color: white;
}

.tutorialbuttondiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
}

.tutorialbuttondiv button {
  width: 90%;
  margin: 10px 0;
  padding: 10px 0;
  border-radius: 50px;
  color: white;
  border: none;
  background: linear-gradient(90deg, #fe4040, #fe7640);
  font-weight: 600;
  font-size: 17px;
  height: 55px;
  line-height: 17px;
}

.tutorialbuttondiv button:nth-child(2) {
  background: transparent;
  margin: 0;
  color: #aaaaaa;
}

.tutorialmain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

.singletutoriallanguage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid white;
  border-radius: 10px;
  width: calc(90% - 20px);
  padding: 0 20px;
  height: 100px;
  color: white;
  margin: 10px 0;
  font-weight: 600;
  transition: all 0.3s;
}

.singletutoriallanguage img {
  height: 90%;
  width: 100px;
  object-fit: contain;
}

.tutorialdiv.first {
  position: relative;
  height: 100vh;
  background: rgba(38, 38, 38, .5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* background: linear-gradient(
      rgba(0, 0, 0, 0.8),
      transparent,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.8)
    ),
    url("./images/tutorialimg.jpg"); */
  background-position: center;
  background-size: cover;
  justify-content: flex-end;
}

.tutorialdiv.first > .tutorialbuttondiv {
  margin-bottom: 25px;
}

.tutorialdiv.first h1 {
  color: white;
  font-family: "Bebas Neue", sans-serif;
  font-size: 80px;
  text-transform: uppercase;
  line-height: 70px;
  margin-bottom: 20px;
}

.tutorialmain p {
  color: white;
  text-align: center;
  margin-top: 20px;
  font-size: 17px;
  font-weight: 500;
}

.tutorialprofile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tutorialprofileoverlay {
  background-color: grey;
  z-index: 9;
  height: 7rem;
  width: 7rem;
  position: absolute;
  border-radius: 100%;
  opacity: 50%;
}

.musicwrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  overflow: hidden;
  padding-top: 15px;
}

.musictop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.musictop label {
  position: relative;
  width: 100%;
}

.musictop input {
  padding: 10px 5px;
  padding-left: 35px;
  border-radius: 10px;
  background-color: #4e4d4c;
  border: none;
  font-size: 17px;
  color: white;
  width: calc(100% - 50px);
}

.musictop input::-webkit-input-placeholder {
  color: #dfdfde;
  font-size: 17px;
}

.musictop input:-ms-input-placeholder {
  color: #dfdfde;
  font-size: 17px;
}

.musictop input::-ms-input-placeholder {
  color: #dfdfde;
  font-size: 17px;
}

.musictop input::placeholder {
  color: #dfdfde;
  font-size: 17px;
}

.musictitle {
  color: white;
  margin: 5px 0;
  font-size: 18px;
  width: 90%;
  font-size: 15px;
}

.musictop p {
  color: white;
  font-size: 17px;
  margin: 0;
  padding: 17px 0;
}

.musiclist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  overflow-y: scroll;
}

.musiclist::-webkit-scrollbar {
  display: none;
}

.singlemusic {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  position: relative;
  margin: 8px 0;
  transition: all 0.3s;
  width: calc(100% - 10px);
}

.singlemusic:active {
  transform: scale(0.9);
}

.singlemusic img {
  height: 90%;
  width: 65px;
  object-fit: contain;
  border-radius: 5px;
}

.musictitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
}

.musictitle h2 {
  margin: 0;
  font-size: 15px;
  color: #fcfcfc;
}

.musictitle p {
  font-size: 12px;
  margin: 0;
  color: #c7c4c3;
}

.musicplayer {
  position: absolute;
  right: 0;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  border: 2px solid #bebfbd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toplaylist {
  width: 90%;
  height: 70px;
  margin: 10px 0;
  border-radius: 20px;
  background-color: #7f7a7add;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.selectnow {
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.likediv {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.likediv img {
  height: 125px;
  width: auto;
  object-fit: contain;
  background-color: white;
  border-radius: 100%;
  z-index: 1;
}

.likediv .addNewFriendsIcon {
  width: 100px;
  height: auto;
  color: #fd7f4d;
  z-index: 1;
  margin-bottom: -10px;
}

.selectnow h2 {
  color: #acacb9;
  text-align: center;
  font-size: 20px;
  max-width: 80%;
  margin-bottom: 15px;
  margin-top: 35px;
}

.selectnow p {
  color: #acacb9;
  text-align: center;
  font-size: 18px;
  margin: 0;
  max-width: 80%;
}

.likeicon {
  position: absolute;
  -webkit-animation: firstlikeanimation 900ms ease-in-out forwards;
          animation: firstlikeanimation 900ms ease-in-out forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  color: #fd5e00 !important;
  transition: 0.2s all ease-in-out;
}

.likeicon:nth-child(2) {
  position: absolute;
  -webkit-animation: likeanimation2 900ms ease-in-out forwards;
          animation: likeanimation2 900ms ease-in-out forwards;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
  color: #fe7f00 !important;
  transition: 0.2s all ease-in-out;
}

.likeicon:nth-child(3) {
  position: absolute;
  -webkit-animation: likeanimation3 900ms ease-in-out forwards;
          animation: likeanimation3 900ms ease-in-out forwards;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  color: #feb000 !important;
  transition: 0.2s all ease-in-out;
}

.likeicon:nth-child(4) {
  position: absolute;
  -webkit-animation: likeanimation4 900ms ease-in-out forwards;
          animation: likeanimation4 900ms ease-in-out forwards;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  color: #feb100 !important;
  transition: 0.2s all ease-in-out;
}

@-webkit-keyframes firstlikeanimation {
  0% {
    right: 25%;
    bottom: 10%;
    opacity: 0%;
  }
  50% {
    opacity: 70%;
  }
  100% {
    bottom: 120%;
    right: 25%;
    opacity: 0%;
  }
}

@keyframes firstlikeanimation {
  0% {
    right: 25%;
    bottom: 10%;
    opacity: 0%;
  }
  50% {
    opacity: 70%;
  }
  100% {
    bottom: 120%;
    right: 25%;
    opacity: 0%;
  }
}

@-webkit-keyframes likeanimation2 {
  0% {
    right: 18%;
    bottom: 0%;
    opacity: 0%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    bottom: 110%;
    right: 17%;
    opacity: 0%;
  }
}

@keyframes likeanimation2 {
  0% {
    right: 18%;
    bottom: 0%;
    opacity: 0%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    bottom: 110%;
    right: 17%;
    opacity: 0%;
  }
}

@-webkit-keyframes likeanimation3 {
  0% {
    left: 20%;
    bottom: 20%;
    opacity: 0%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    bottom: 130%;
    left: 20%;
    opacity: 0%;
  }
}

@keyframes likeanimation3 {
  0% {
    left: 20%;
    bottom: 20%;
    opacity: 0%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    bottom: 130%;
    left: 20%;
    opacity: 0%;
  }
}

@-webkit-keyframes likeanimation4 {
  0% {
    left: 25%;
    bottom: 0%;
    opacity: 0%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    bottom: 140%;
    left: 25%;
    opacity: 0%;
  }
}

@keyframes likeanimation4 {
  0% {
    left: 25%;
    bottom: 0%;
    opacity: 0%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    bottom: 140%;
    left: 25%;
    opacity: 0%;
  }
}

.uploadchoicewrapper {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.singlechoice {
  width: calc(100% - 40px);
  padding: 0 20px;
  border-radius: 10px;
  border: 2px solid white;
  margin: 10px 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 22px;
  letter-spacing: .5px;
  font-weight: 500;
}

.eventtypecontainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
}

.singleeventtype {
  background: #16181a;
  border: 1px solid #323638;
  border-radius: 10px;
  color: white;
  width: calc(50% - 10px);
  margin: 5px;
  height: 130px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
}

.singleeventtype span {
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 18px;
}

.singleeventtype:nth-child(even) {
  margin-right: 0;
}

.singleeventtype:nth-child(odd) {
  margin-left: 0;
}

.businessSuccesswrapper {
  height: 95vh;
  width: 100vw;
  border-radius: 45px;
  overflow: hidden;
  max-width: 550px;
  background: black;
  position: relative;
}

.overflowbusinesstop {
  background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.301), black);
  height: 160px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.overflowbusinessbottom {
  background: linear-gradient(180deg, transparent,  rgba(0, 0, 0, 0.301), black);
  height: 160px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 500px) {
  .businessSuccesswrapper {
    height: 100vh;
    width: 100vw;
    max-width: none;
    border-radius: 0;
    max-height: none;
  }
}

.businessSuccessimage {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  -webkit-animation: secondSuccessAnimation 1.5s 2s ease-in-out forwards;
          animation: secondSuccessAnimation 1.5s 2s ease-in-out forwards;
  opacity: 0;
  transform: scale(0);
}

@-webkit-keyframes secondSuccessAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  55% {
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: .6;
    transform: scale(1);
  }
}

@keyframes secondSuccessAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  55% {
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: .6;
    transform: scale(1);
  }
}

.successText {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: firstSuccessAnimation 3s forwards;
          animation: firstSuccessAnimation 3s forwards;
}

.successText > h2 {
  color: white;
  font-size: 45px;
  font-family: "Staatliches", cursive;
  text-transform: uppercase;
}

@-webkit-keyframes firstSuccessAnimation {
  0% {
    left: 200%;
  }
 50% {
  left: 50%;
 }
  100% {
    left: -200%;
  }
}

@keyframes firstSuccessAnimation {
  0% {
    left: 200%;
  }
 50% {
  left: 50%;
 }
  100% {
    left: -200%;
  }
}

.businessSuccesswrapper h1 {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Staatliches", cursive;
  font-size: 70px;
  background: -webkit-linear-gradient(45deg, #fe4040, #fe7640);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  line-height: 55px;
  opacity: 0;
  -webkit-animation: businessSuccessTitle .5s 3.5s forwards;
          animation: businessSuccessTitle .5s 3.5s forwards;
  word-wrap: break-word;
  padding-top: 1px;
}

@-webkit-keyframes businessSuccessTitle {
  0% {
    opacity: 0;
    top: 60%;
  }
  100% {
    opacity: 1;
    top: 50%;
  }
}

@keyframes businessSuccessTitle {
  0% {
    opacity: 0;
    top: 60%;
  }
  100% {
    opacity: 1;
    top: 50%;
  }
}

.businessCreate.businessSuccessButton {
  -webkit-animation: businessSuccessButtonAnimation 1.5s 4s forwards;
          animation: businessSuccessButtonAnimation 1.5s 4s forwards;
  opacity: 0;
}

@-webkit-keyframes businessSuccessButtonAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes businessSuccessButtonAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notificationpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin: 0 20px;
  margin-bottom: calc(env(safe-area-inset-bottom));
}

.notificationheader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid #1e1c1c;
  padding: 0 20px;
}

.notificationheader h2 {
  margin-left: 20px;
  color: white;
  font-size: 25px;
  text-transform: capitalize;
}

.notificationbody {
  width: 100%;
  overflow-x: hidden;
}

.notificationbody::-webkit-scrollbar {
  display: none;
}

.singlenotification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;
  position: relative;
  transition: all .2s;
  height: 48px;
}

.singlenotification.animation {
  -webkit-animation: disapearAnimation .4s forwards;
          animation: disapearAnimation .4s forwards;
}

@-webkit-keyframes disapearAnimation {
  0% {
    height: 48px;
    padding: 15px 0;
    transform: scaleY(1);
  } 100% {
    height: 0px;
    padding: 0px;
    transform: scaleY(0);
  }
}

@keyframes disapearAnimation {
  0% {
    height: 48px;
    padding: 15px 0;
    transform: scaleY(1);
  } 100% {
    height: 0px;
    padding: 0px;
    transform: scaleY(0);
  }
}

.singlenotification p {
  color: white;
  font-size: 16px;
  margin: 0;
  line-height: 15px;
  margin-left: 10px;
  font-weight: 500;
  max-width: 175px;
}

.singlenotification span {
  color: #8c8c8c;
  word-break: break-word;
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
}

.notificationbuttons {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.notificationbuttons .accept {
  border: none;
  border-radius: 7px;
  font-weight: 600;
  background-color: #4380eb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.5rem;
  height: 37px;
}

.notificationimage {
  height: 40px;
  width: 40px;
  background-color: grey;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
}

.unreadbadge {
  position: relative;
}

.badgespan {
  position: absolute;
  background-color: red;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  bottom: 0;
  right: 15px;
}

.chatdot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: #00a9fe;
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.futureEventInfo {
  height: 30px;
  width: 100%;
  margin: 10px 0;
  background-color: #1f1f1f;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 15px 0;
  color: grey;
}

.privateEventDisplayWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  transition: all 0.3s;
  width: 100%;
}

.privateEventDisplayWrapper:active {
  transform: scale(0.97);
}

.privateEventDisplayBody {
  height: 220px;
  width: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.privateEventDisplayOverlay {
  height: 45%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, .85), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4), transparent);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}


.privateEventInnerInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 80px;
}

.privateEventInnerInfo h2 {
  color: white;
  margin: 0;
  font-size: 24px;
}

.privateEventInnerInfo p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 500;
}

.privateEventInnerButton {
  height: 70px;
  width: 70px;
  padding: 5px;
  border-radius: 100%;
  background: linear-gradient(90deg, #fe4040, #fe7640);
  position: absolute;
  right: 10px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.privatePartyMembersWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.privateEventNumberCount {
  color: grey !important;
  font-size: 10px !important;
  font-weight: 600;
  z-index: 1;
  display: flex;
  position: absolute;
  top: -10px;
  right: -7px;
  align-items: center;
  justify-content: center;
}

.secretSearchWrapper {
  padding: 0 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: 1s;
}

.secretHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  padding-top: 25px;
}

.secretHeaderTitle {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin: 25px 0;
}

.secretCodeIndicators {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.singleCodeIndicator {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 1px solid grey;
  margin: 0 10px;
}

.pinwrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.pinNumberWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  max-width: 500px;
}

.pinnumber {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.innerPinNumber {
  color: white;
  font-size: 26px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #1e1c1c;
  background-color: #212121;
  border-radius: 100%;
  transition: 200ms;
}

.innerPinNumber:active {
  background-color: #ffffff69;
}

.secretSearchResultWrapper {
  height: calc(100vh - 80px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  margin-bottom: 50px;
  transition: 1s;
}

.secretSearchResultWrapper::-webkit-scrollbar {
  display: none;
}

@media (max-width: 375px) {
  .pinnumber {
    margin: 8px 0;
  }
  .innerPinNumber {
    height: 35px;
    width: 35px;
  }
}

.chatBadge {
  text-transform: uppercase;
  padding: 7px 20px;
  border-radius: 20px;
  background-color: white;
  color: black;
  font-size: 12px;
  font-weight: 600;
  margin-top: -10px;
}

.paymentmodal {
  margin: 0 20px;
  padding: 30px;
  border-radius: 20px;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paymentmodal p {
  color: white;
}

.bugInfoWrapper {
  padding: 30px;
  margin: 20px;
  background-color: #333333;
  position: absolute;
  left: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .5s all;
  z-index: 10;
}

.bugInfoWrapper p {
  color: white;
  text-align: center;
  font-weight: 500;
}

.businessInfoWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
}

.businessInfoWrapper h2 {
  color: #acacb9;
    text-align: center;
    font-size: 20px;
    max-width: 80%;
    margin: 10px 0;
}

.businessInfoWrapper p {
  color: white;
  background-color: transparent;
  font-size: 15px;
  max-width: 75%;
  margin: 20px 0;
  margin-top: 15px;
  font-weight: 500;
  text-align: center;
}

.editEventWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin: 0 24px;
}

.activeLabel {
  height: 40px;
  text-transform: uppercase;
  width: 100px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 19px;
  border-radius: 10px;
  background: #e73f40;
}

.selectwrapper {
  position: relative;
}

.selecter {
  width: 90px;
  height: 30px;
  border: 2px solid #fd7f4d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1f1f1f;
}

.selecter h2 {
  font-size: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectlist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #1f1f1f;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.selectlist h2 {
  font-size: 15px;
  color: white;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.selectlist h2:first-child {
  border-radius: 10px 10px 0 0;
}

.selectlist h2:last-child {
  border-radius: 0 0 10px 10px;
}

.postviews {
  background-color: #1f1f1f;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.postviews h2 {
  color: #a5a5a5;
  font-size: 19px;
  margin: 0 20px;
}
